function fetchGSheet2(range, toSet) {
    function start() {
        // 2. Initialize the JavaScript client library.
        return window.gapi.client.init({
            'apiKey': process.env.GATSBY_GSHEET_API,
            'discoveryDocs': ['https://sheets.googleapis.com/$discovery/rest?version=v4']
        }).then(e => {
            var params = {
                spreadsheetId: process.env.GATSBY_GSHEET_ID,
                range: range,
            };
            var request = window.gapi.client.sheets.spreadsheets.values.get(params);
            request.then(function(response) {
                let values = response.result.values;
                let keys = values.shift();
                let all_entries = values.map((v) => {
                        return keys.map((k, i) => {
                        return {[k]: v[i]}
                    })
                }).map(entry => Object.assign({}, ...entry))
                toSet(all_entries)
            }, function(reason) {
                console.error('error: ',reason);
                setTimeout(fetchGSheet2, 60000)
            });
        })
    };
    // 1. Load the JavaScript client library.
    window.gapi.load('client', start)
}

export default fetchGSheet2;