import * as React from 'react'
import Layout from '../component/layout'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { ContactContext } from '../context/contact'
import fetchGSheet2 from '../component/fetchGSheet'

const Meetings = () => {
    const [entries, setEntries] = React.useState(undefined)
    const [meetings, setMeetings] = React.useState([])

    React.useEffect(() => {
        fetchGSheet2('Gatherings!A:E', setEntries)
        if (entries === undefined) return;
        setMeetings(entries)
    }, [entries])

    return (
        <Layout title={'Gatherings | YouYou'}>
            <div className='grid grid-rows-auto grid-cols-1 md:grid-cols-2 gap-4 justify-center items-stretch m-3 md:m-10 flex'>
                {/* row: 1 col on small screen, 2 cols on bigger screens */}
                <div className='col-span-1 font-sylexiad'>
                    <h1 className='title'>When do we meet?</h1>
                    <p className='text text-xl md:text-2xl'>
                    YouYou sharing sessions are held on Tuesdays, 8pm to 10pm.
                    <br /><br />Sometimes we have board games, eat together, or hold other social events on certain dates. 
                    <br /><br />Please contact us if you are interested in visiting, and we will make space for you!
                    </p>
                    <br />
                    <div className='justify-center text-center'>
                        <Link to='/contact'><button className='general-button'>Contact Us!</button></Link>
                    </div>
                </div>
                <div className='col-span-1 font-sylexiad'>
                    <StaticImage src='../images/image-asset3.webp'
                                alt='People gathering on hill'
                                className='rounded-md shadow-lg' />
                </div>
            </div>
            {meetings ? 
            <div className='grid grid-rows-auto grid-cols-1 md:grid-cols-3 gap-4 justify-center items-stretch m-3 md:m-10'>
                {meetings.map((m, i) => (
                    <div className='col-span-1 font-sylexiad text-center border-gray-400 border-2 rounded-md pt-4 pb-4 shadow-lg' key={i}>
                        <strong className='title text-2xl md:text-3xl'>{m.date}</strong>
                        <p className='font-sylexiad-bold text-2xl md:text-3xl'>{m.title}</p>
                        <p className='subtitle md:text-xl'>{m.dateDetailed}</p>
                        <p className='font-sylexiad italic text-xl'>{m.description}</p>
                        {m.additionalInformation ? <p className='font-sylexiad text-base m-2'>{m.additionalInformation}</p>: null}
                        <p className='font-sylexiad-bold text-base mt-4'>Please email / Facebook Message us for details.</p>
                    </div>
                ))}
            </div>
            : null}
            
            <div className='grid grid-rows-auto grid-cols-1 md:grid-cols-2 gap-4 justify-center items-stretch m-3 md:m-10'>
                <div className='col-span-1 font-sylexiad'>
                    <div className='text-center'>
                        <strong className='title'>Other Announcements and Activities</strong>
                    </div>
                    <ol className='statement-list pl-4'>
                        <li>We’ve gone online via Steam and Discord for tabletop gaming. Spectators welcome!</li>
                        <ContactContext.Consumer>
                            {(emailURL) => 
                            <li>Interested in any of the above events or want to help out? Feel free to email <a href={emailURL.emailURL} target='_blank' className='underline' rel='noreferrer'>youyouwuv@gmail.com</a> or Facebook message (<a href={'https://www.facebook.com/youyouwuv/'} target='_blank' className='underline' rel='noreferrer'>www.facebook.com/youyouwuv/</a>)! Or contact Elle if you know her! You know she loves to hear from you.</li>}
                        </ContactContext.Consumer>
                    </ol>
                </div>
                <div className='col-span-1 font-sylexiad text-center'>
                    <StaticImage src='../images/Art_from_UU_Philippines_Minister.webp'
                                alt='Art'
                                className='rounded-md shadow-lg' />
                    <p className='text text-sm'>You’re always cute to someone.</p>
                </div>
            </div>
        </Layout>
    )
}

export default Meetings;